import BackgroundImage from 'gatsby-background-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { breakpointLarge } from '../../styles/breakpoints';

const ContentContainer = styled.div`
  color:black;
  background-color: ${props => props.theme.yellow};
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  @media (min-width: ${breakpointLarge}) {
    padding-bottom:5rem;
    justify-content:start;
    align-items:start;
  }
`;

const Title = styled.h1`
  width:200px;
  color:black;
  margin-bottom: 0.625rem;
  line-height: 0.9;
  font-weight:800;
  font-size:2rem;
  text-align:center;
  @media (min-width: ${breakpointLarge}) {
    font-size:2.7rem;
    text-align:start;
    width:280px;
  }
`;
const Description = styled.div`
  width:300px;
  text-align:center;
  font-size: 1rem;
  @media (min-width: ${breakpointLarge}) {
    text-align:start;
    width:350px;
    font-size: 1.2rem;
  }
`;
const ContainerMain = styled.div`
  display:flex;
  justify-content:center;
  width: 100%;
  background-color: ${props => props.theme.yellow};

`
const ContentSection = styled.div`
  width: 100%;
  display:flex;
  justify-content:center;
  align-items:center;
  max-width: 70rem;
  flex-direction:column;
  @media (min-width:700px) {
    padding:0 2.5rem;
  }
  @media (min-width: ${breakpointLarge}) {
    justify-content:space-between;
    flex-direction:row;
    margin:0 2.5rem;
  }
  @media (min-width:1240px) {
    padding:0;
  }
`
const PageHeaderStyled = styled(BackgroundImage)`
  display: none; 
  position: relative;
  background-position: ${props => props.position}%;
  z-index:2;
  @media (min-width: ${breakpointLarge}) {
    width:600px;
    height:336px;
    margin-right:5rem;
    display: block; 
  }
`;

const PageHeaderStyledMobile = styled(BackgroundImage)`
  z-index:2;
  min-width:320px;
  height:276px;
  position: relative;
  background-position: ${props => props.position}%;
  display: block; 
  @media (min-width: 600px) {
    width:490px;
    height:420px;
  }
  @media (min-width: ${breakpointLarge}) {
    display: none;
  }
`;
const PageHeaderPro = ({ image, title, description, backgroundPosition ,imageMobile }) => {
  return (
   <ContainerMain>
      <ContentSection>
        <ContentContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </ContentContainer>
        <PageHeaderStyled
          fluid={[
            image.childImageSharp.fluid,
          ]}
          preserveStackingContext={true}
          position={
            typeof backgroundPosition === 'number' ? backgroundPosition : 50
          }
        >
        </PageHeaderStyled>
        <PageHeaderStyledMobile
          fluid={[
            imageMobile.childImageSharp.fluid,
          ]}
          preserveStackingContext={true}
          position={
            typeof backgroundPosition === 'number' ? backgroundPosition : 50
          }
        >
        </PageHeaderStyledMobile>
      </ContentSection>
  </ContainerMain>
  );
};

PageHeaderPro.propTypes = {
  image: PropTypes.object.isRequired,
  backgroundPosition: PropTypes.number,
  title: PropTypes.string.isRequired,
  imageMobile: PropTypes.object.isRequired,
  description: PropTypes.string,
};

export default PageHeaderPro;