import styled from 'styled-components';

const ContentStyled = styled.div`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  
  @media (min-width: 560px) {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
`;

export default ContentStyled;
