import Cookie from 'js-cookie';

export const getUtmCookie = (() => {
  return Cookie.get('utm') ? Cookie.get('utm') : '';
})();
export const getCookieAccept = () => {
  return Cookie.get('jtp_cookie') ? true : false;
};
export const createCookie = (
  name,
  value,
  days = 365,
) => {
  let GATSBY_JTP_ENV = process.env.GATSBY_JTP_ENV;
  let NODE_ENV = process.env.NODE_ENV;
  let domain = 
    NODE_ENV === 'production' 
      ? GATSBY_JTP_ENV === 'TEST' 
        ? '.vertical.com.co' 
        : '.juanchotepresta.com' 
      : ''
  Cookie.set(name, value, { expires: days, path: '/', domain });
};
