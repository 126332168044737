import styled from 'styled-components';
import { breakpointLarge } from '../../styles/breakpoints';
import SVGColor from './SVGColor';

const ButtonBtn = styled.button`
  font-weight: 300;
  display: inline-block;
  padding: 1rem 1.75rem;
  color: ${p => p.theme.blueDark};
  background-color: #fff;
  border: 1px solid ${p => p.theme.blueDark};
  border-radius: 3.5rem;
  position: relative;
  text-transform: uppercase;
  transition: all 0.25s ease;
  cursor:pointer;
  &:hover {
    background-color: #fff;
    color: ${props => props.theme.blueDark};
    ${SVGColor(props => props.theme.blueDark)}
    border-color: ${p => p.theme.blueDark};
  }
  @media (min-width: ${breakpointLarge}) {
    padding: 1.5rem 2.5rem;
    
  }
`;

export default ButtonBtn;
