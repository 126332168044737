import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/UI/Button';
import ButtonBtn from '../components/UI/ButtonBtn';
import SVGColor from '../components/UI/SVGColor';
import { createCookie, getCookieAccept } from '../helpers/cookies';
import { breakpointLarge } from '../styles/breakpoints';

const Container = styled.div`
    position: fixed;
    bottom: ${props => props.ControlCookie ? '-122px' : '0px'};
    background-color: ${props => props.theme.blueDark};
    width: 100%;
    height: 122px;
    z-index: 100;
    opacity: 0.94;
    padding: 5px 12px;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
`;

const TextContainer = styled.div`
  color: #fff;
  margin:5px 0px;
  padding: 8px 0px;
  font-size: 0.75rem;
  line-height: 14.4px;
  @media (min-width: ${breakpointLarge}) {
    text-align:center;
    font-size: 1rem;
    padding: 12px 0px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${SVGColor('#fff')}
  & > a {
    font-weight: 400;
    padding: .4rem 1.3rem;
    font-size: 0.75rem;
    @media (min-width: ${breakpointLarge}) {
      padding-left: 1.9rem;
      padding-right:1.9rem;
      font-size: 1rem;
    }
  }
`;

const Button3 = styled(Button)`
  background-color: ${props => props.theme.blueLightness};
  border-color: ${props => props.theme.blueLightness};
  color: #fff;
  margin: 0px 6px;
`;

const Button4 = styled(ButtonBtn)`
  color: ${props => props.theme.blueDark};
  margin: 0px 6px;
  padding: 6.4px 10px;
  background-color: ${props => props.theme.yellow};
  border-color: ${props => props.theme.yellow};
  font-size:0.75rem;
  @media (min-width: ${breakpointLarge}) {
    font-size:1rem;
    padding: 6.4px 30.4px;
  }
  
`;


const Cookies = () => {

  const [ControlCookie, setControlCookie] = useState(true);

  const validCookie = () => {
    setControlCookie(getCookieAccept());
  };

  const acceptCookie = () => {
    createCookie(
      'jtp_cookie',
      JSON.stringify({
        timestamp: Date.now(),
        request: false,
      }),
      365
    );
    validCookie();
  };

  useEffect(() => {
    validCookie();
  }, []);

    return (  
        <Container ControlCookie={ControlCookie}>
          <TextContainer>
            En Juancho usamos varios tipos de cookies para mejorar tu experiencia en nuestro sitio web. Al seguir navegando o hacer click en Aceptar permites su uso.
          </TextContainer>
          <ButtonContainer>
            <Button4 onClick={() => acceptCookie()}>
              aceptar
            </Button4>
            <Button3 href="https://juanchotepresta.com/img/política_de_cookies_jtp.pdf" target="_blank"
            aria-label="POLÍTICA DE USOS DE COOKIES"
            >
              políticas de cookies
            </Button3>    
          </ButtonContainer>
        </Container>
    )
}

export default Cookies