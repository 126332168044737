import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Cookies from '../components/Cookies';
import Layout from '../components/Layout';
import ContentContainer from '../components/UI/ContentContainer';
import PageContent from '../components/UI/PageContent';
import PageHeaderPagosInfo from '../components/UI/PageHeaderPagosInfo';
import { breakpointLarge } from '../styles/breakpoints';

const PageContentOverlap = styled(PageContent)`
  margin-top: -4.5rem;
  z-index: 98;
  position: relative;
  @media (min-width: 950px) {
    margin-top: -5.8rem;
  }
`;
const Continermine = styled.div`
  background-color: #fff;
  padding:1.6rem;
  border-radius:2.5rem;
  @media (min-width: ${breakpointLarge}) {
    padding:3rem;
  }
`;
const ContentPse = styled.div`
  display: flex;
  align-items:center;
  @media (min-width: ${breakpointLarge}) {
    margin: 0.5rem;
  }
`;
const Item = styled.div`
  padding-top: 1rem;
  display: flex;
  align-items:center;
  @media (min-width: ${breakpointLarge}) {
    padding-top: 1.5rem;
    margin: 0.5rem;
  }
`;
const ItemIcon = styled(Img)`
  width: 40px;
  height:40px;
  @media (min-width: ${breakpointLarge}) {
    width:80px;
    height:80px;
  }
`;
const ContinerIcon = styled.div`
  background-color: ${props => props.theme.grayLight};
  border-radius: 0.8rem;
  displey:flex;
  padding: 0.5rem;
  justify-content:center;
  align-items:center;
  margin-right: 1rem;
  width: 55px;
  height:55px;
  @media (min-width: ${breakpointLarge}) {
    width:125px;
    height:125px;
    padding: 1.6rem;
    border-radius: 1.3rem;
  }
`
const ItemText = styled.div`
  h3 {
    color: ${props => props.theme.gray};
    margin: 0;
    font-size: 1rem;
    line-height: 0.9;
    font-weight: 700;
    color:black;
  }
  p {
    color:gray;
    margin: 0;
    text-align:start;
    font-weight: 600;
  }
  @media (min-width: ${breakpointLarge}) {
    max-width:480px;
    width:100%;
    h3 {
      font-size: 1.2rem;
      line-height: 0.9;
      font-weight: 700;
    }
    p {
      margin-top:0.5rem
    }
  }
`;
const ItemText2 = styled.div`
  h3 {
    color: ${props => props.theme.gray};
    margin: 0;
    font-size: 1.3rem;
    line-height: 0.9;
    font-weight: 800;
  }
  p {
    color:gray;
    margin: 0;
    text-align:start;
    font-weight: 400;
    a{
      color:${props => props.theme.blue};
      font-weight: 600;
    }
  }
  @media (min-width: ${breakpointLarge}) {
    h3 {
      margin: 0;
      font-size: 1.7rem;
      line-height: 0.9;
      font-weight: 800;
    }
    p {
      margin:0.5rem 0;
    }
  }
`;
const Titel1 = styled.div`
  margin-top:1rem;
h4{
  color:${props => props.theme.gray};
  margin:0;
  font-size: 1.3rem;
  font-weight: 700;
}
p{
  color:gray;
  margin:0;
  text-align:start;
}
@media (min-width: ${breakpointLarge}) {
  margin-top:1.5rem;
  h4 {
    margin: 0;
    font-size: 1.7rem;
    font-weight: 800;
  }
}

`
const Description1 = styled.div`
color:gray;
p{
  span{
    font-weight: 600;
  }
  text-align:start;
  a{
    color:${props => props.theme.blue};
    font-weight: 500;
  }
}
@media (min-width: ${breakpointLarge}) {
  margin-top:2rem;
}
`
const Description2 = styled.div`
color:black;
p{
  span{
    font-weight: 600;
  }
  text-align:start;
}
`
const TextDescrption = styled.div`
 p{
  margin:0;
 }
  display:flex;
  flex-direction:column;
  @media (min-width: ${breakpointLarge}) {
    flex-direction:row;
    p {
      margin-top:0.5rem;
    }
    p:first-of-type {
      margin-right: 0.3rem; 
    }
  }
`
export const PagosPageTemplate = ({ 
  image,
  imageMobile,
  imagePosition,
  title,
  description,
  iconPse,
  itemsTransferenciaBancaria,
  iconPuntoRed,
  iconEfecty,
}) => {
 

  return (
    <section>
        <Cookies/>
        <PageHeaderPagosInfo
          image={image}
          imageMobile={imageMobile}
          backgroundPosition={imagePosition}
          title={title}
          description={description}
        />
        <PageContentOverlap>
        <ContentContainer>
        <Continermine>
              <ContentPse>
                <ContinerIcon>
                <ItemIcon
                  fluid={iconPse.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: 'fill',
                  }}
                />
                </ContinerIcon>
                <ItemText2>
                  <h3>PSE</h3>
                  <p>Ingresa a <a href="https://app.juanchotepresta.com/login">
                    Mi cuenta</a> con tu usuario y contraseña, usando el
                    botón de pagos PSE.
                  </p>
                </ItemText2>
              </ContentPse>
            <Titel1>
              <h4>Transferencia Bancaria:</h4>
              <p>Estas son nuestras cuentas oficiales y habilitadas:</p>
            </Titel1>
            {itemsTransferenciaBancaria.map((itemTransferencia, index)=> (
              <Item key={itemTransferencia.bank}>
                <ContinerIcon>
                <ItemIcon
                  fluid={itemTransferencia.icon.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: 'fill',
                   
                  }}
                />
                </ContinerIcon>
                <ItemText>
                  <h3>{itemTransferencia.bank}:</h3>
                  <TextDescrption>
                    <p>{itemTransferencia.accountType}</p>
                    <p>{itemTransferencia.accountNumber}</p>
                  </TextDescrption>
                  {index === 0 ? (<p>Número de convenio: 92504</p>):""}
                </ItemText>
              </Item>
            ))}
            <Description1>
              <p>Realiza la transferencia a nombre de:
                 <span> Juancho Te Presta S.A.S NIT 901.200.575-1 </span>
                 y en <span>"Referencia de pago"</span> pon tu número de cédula.
              </p>
            </Description1>
            <Description1>
              <p> No olvides enviar el comprobante de pago de la transferencia 
                bancaria al correo: <a href="mailto:cartera@juanchotepresta.co">
                cartera@juanchotepresta.co</a>
              </p> 
            </Description1>
            <Titel1>
              <h4>Puntos de pago físicos:</h4>
              <p>Si quieres pagar en efectivo, puedes hacerlo en:</p>
            </Titel1>
              <Item>
                <ContinerIcon>
                <ItemIcon
                  fluid={iconPuntoRed.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: 'fill',
                   
                  }}
                />
                </ContinerIcon>
                <ItemText>
                  <h3>Punto Red:</h3>
                  <p>Código: 11698</p>
                  <p>Referencia: Tu cédula</p>
                </ItemText>
              </Item>
              <Item>
                <ContinerIcon>
                <ItemIcon
                  fluid={iconEfecty.childImageSharp.fluid}
                  imgStyle={{
                    objectFit: 'fill',
                   
                  }}
                />
                </ContinerIcon>
                <ItemText>
                  <h3>Efecty:</h3>
                  <TextDescrption>
                    <p>Nombre del convenio:</p>
                    <p>Juancho Te Presta.</p>
                  </TextDescrption> 
                  <p>Número del convenio: 111615</p>
                </ItemText>
              </Item>
            <Description2>
              <p><span>Si tienes habilitada la opción de débito automático, </span> 
              recuerda tener el debito en la cuenta en la fecha de vencimiento de tu cuota.
              </p>
              <p>Los pagos por canales distintos de PSE se pueden 
                demorar hasta 3 días hábiles para aplicarse, 
                puedes seguir recibiendo mensajes de mora.
              </p>
            </Description2>
        </Continermine>
        </ContentContainer>
      </PageContentOverlap>
    </section>
  );
};

PagosPageTemplate.propTypes = {
  image: PropTypes.object,
  imagePosition: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageMobile:PropTypes.object,
  iconPse: PropTypes.object,
  itemsTransferenciaBancaria: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      bank: PropTypes.string,
      accountType: PropTypes.string,
      accountNumber: PropTypes.string,
    })
  ),
  iconPuntoRed: PropTypes.object,
  iconEfecty: PropTypes.object,
};
const PagosPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PagosPageTemplate
        image={post.frontmatter.image}
        imageMobile={post.frontmatter.imageMobile}
        imagePosition={post.frontmatter.imagePosition}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        iconPse={post.frontmatter.iconPse}
        itemsTransferenciaBancaria={post.frontmatter.itemsTransferenciaBancaria}
        iconPuntoRed={post.frontmatter.iconPuntoRed}
        iconEfecty={post.frontmatter.iconEfecty}
      />
    </Layout>
  );
};
PagosPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PagosPage;

export const PagosPageQuery = graphql`
  query PagosPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2018, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imageMobile{
          childImageSharp {
            fluid(maxWidth: 2018, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imagePosition
        title
        description
        iconPse {
          childImageSharp {
            fluid(maxWidth: 150, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        itemsTransferenciaBancaria{
          icon {
            childImageSharp {
              fluid(maxWidth: 150, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bank
          accountType
          accountNumber
        }
        iconPuntoRed {
          childImageSharp {
            fluid(maxWidth: 150, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        iconEfecty {
          childImageSharp {
            fluid(maxWidth: 150, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
